<template>
  <div class="login-form">
    <h3 class="title has-text-black" style="text-align:center;">Sign In</h3>
    <p class="subtitle">Please sign in to proceed.</p>
    <form autocomplete="off">
      <div class="field">
        <div class="control">
          <input
            class="input"
            type="email"
            placeholder="Your Email"
            autofocus=""
            :disabled="loading"
            v-model="form.email"
          />
        </div>
      </div>
      <div class="field">
        <div class="control">
          <input
            class="input"
            type="password"
            placeholder="Your Password"
            :disabled="loading"
            v-model="form.password"
          />
        </div>
      </div>
      <button
        class="button is-block is-primary is-fullwidth is-rounded"
        v-on:click.self.prevent
        @click="onLoginClicked"
        :disabled="loading || invalid"
        :class="{ 'is-loading': loading }"
      >
        Sign In
      </button>
      <p v-if="error" class="help is-danger">
        {{error}}
      </p>
      <p style="text-align:center;">
        <br/>
        <router-link :to="{ name: 'forgot-password' }">
          Forgot Password?
        </router-link>
      </p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  data: function() {
    return {
      form: {
        email: '',
        password: ''
      },
      loading: false,
      error: null
    }
  },
  computed: {
    invalid: function() {
      return !(this.form.email && this.form.password)
    }
  },
  methods: {
    tryLogin: async function() {
      try {

        const response = await this.$fetch('POST', 'auth.login', this.form)

        if (response.error) {
          return response.error.message
        } else if(typeof response !== 'object') {
          return 'An unknown error has occurred. Please try again.'
        }

        // keep track of whether or not the current user is an admin
        response.login.is_admin = response.login.role === 'admin'
        this.$emit('logged-in', response)

        return false

      } catch(e) {
        return e
      }
    },
    onLoginClicked: async function() {
      this.error = null
      this.loading = true

      const error = await this.tryLogin()
      if (error) this.$set(this.$data, 'error', error)

      this.loading = false
    }
  }
}
</script>

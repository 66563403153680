<template>
  <nav class="breadcrumb">
    <ul>
      <li v-if="back"><a href="#" @click="back">Back</a></li>
      <li class="is-active"><a href="#"><slot /></a></li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    back: {
      type: Function
    }
  }
}
</script>

import Vue from 'vue'
import Router from 'vue-router'
import routes from '@/router/routes.js'

Vue.use(Router)

export default function createRouter () {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      ...routes
    ]
  })
}

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    session: null,
    programs: null,
    review_field_list: null,
  },
  actions: {
    checkCachedSession: function({ commit, dispatch }) {
      try {
        let session = localStorage.getItem('session')
        if (!session)
          return dispatch('clearSession')

        session = JSON.parse(session)
        if (typeof session !== 'object' || !session.session_token || !session.login)
          return dispatch('clearSession')

        commit('setSession', session)
      } catch (err) {
        console.warn(err) // eslint-disable-line
        dispatch('clearSession')
      }
    },
    setSession: function({ commit }, payload) {
      localStorage.setItem('session', JSON.stringify(payload))
      commit('setSession', payload)
    },
    clearSession: function({ commit }) {
      localStorage.removeItem('session')
      commit('setSession', null)
    },
    loadReviewFieldList: async function({commit}, callback) {
      commit('loadReviewFieldList', await callback);
    }
  },
  mutations: {
    setSession: function(state, payload) {
      Vue.set(state, 'session', payload)
    },
    loadReviewFieldList: function(state, review_field_list) {
      Vue.set(state, 'review_field_list', review_field_list);
    }
  },
})

export default store

<template>
  <PageView class="view">

    <template #header v-if="program">
      <div class="cell">
        <BackButton class="inline-button" />
        <h2 class="title is-4">
          Program: {{ program.name }} ({{ program.code}})
        </h2>
      </div>
      <div v-if="login && login.role === 'admin'">
        <router-link
          :to="{ name: 'manage-program', params: { id: program.id } }"
          class="button is-primary is-rounded"
        >
          Manage
        </router-link>
        &nbsp;
        <button
          class="button is-primary is-rounded"
          @click="onExportClick"
          :disabled="exporting"
        >
          Export
        </button>
        &nbsp;
        <button
            class="button is-primary is-rounded"
            @click="onAutocompleteClick"
            :disabled="autocompleting"
        >
          Auto-complete
        </button>
      </div>
    </template>

    <div class="view__modal" v-if="selected_candidate">
      <div :class="{ 'modal': true, 'is-active': selected_candidate !== null }">
        <div class="modal-background" @click="unselectCandidate"></div>
        <div class="modal-content">
          <div class="box" v-if="selected_candidate">
            <h4 class="title is-5">{{ selected_candidate.name }}</h4>

            <template v-if="selected_candidate.reviews && selected_candidate.reviews.length">
              <ReviewsModal
                :program_id="program.id"
                :reviews="selected_candidate.reviews"
              />
            </template>
            <template v-else>
              No assigned reviewers.
            </template>

            <br />
            <br />
            <button class="button is-primary is-small is-rounded" @click="unselectCandidate">
              Close
            </button>
          </div>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="unselectCandidate"></button>
      </div>
    </div>

    <template v-if="program && candidates">

      <template>
        <Candidates
          v-if="login && login.role === 'admin'"
          :candidates="candidates"
          :program_id="program.id"
          @view-candidate="selectCandidate"
        />
        <ReviewerCandidates
          v-if="login && login.role === 'reviewer'"
          :candidates="candidates"
          :program_id="program.id"
        />
      </template>

    </template>

    <template v-else>
      LOADING
    </template>

  </PageView>
</template>

<script>
import { mapState } from 'vuex'
import BackButton from '@/components/BackButton.vue'
import PageView from '@/components/PageView.vue'
import Candidates from '@/components/Candidates.vue'
import ReviewsModal from '@/components/ReviewsModal.vue'
import ReviewerCandidates from '@/components/ReviewerCandidates.vue'

export default {
  name: 'ProgramView',
  components: {
    BackButton,
    PageView,
    Candidates,
    ReviewsModal,
    ReviewerCandidates
  },
  data: function() {
    return {
      base_url: process.env.VUE_APP_PT_API_URL,
      program: null,
      candidates: null,
      errored: false,
      selected_candidate: null,
      exporting: false,
      autocompleting: false,
    }
  },
  computed: {
    program_id: function() {
      return parseInt(this.$route.params.id)
    },
    ...mapState({
      login: state => state.session && state.session.login
    })
  },
  created() {
    this.initialize()
  },
  methods: {

    initialize() {
      if (!this.program) this.fetchProgram()
      if (!this.candidates) this.fetchCandidates()
      this.$store.dispatch('loadReviewFieldList', this.$fetch('GET', 'programs.review_field_list?program_id=' + this.program_id))
    },

    async fetchProgram() {

      let url = `programs.get?id=${this.program_id}`
      if(this.login.is_admin && this.login.role !== 'admin') {
        url += `&role=${this.login.role}`
      }

      const program = await this.$fetch('GET', url)

      if (program && program.error) return this.handleError(program.error)

      this.$set(this.$data, 'program', program)

    },

    async fetchCandidates() {

      let url = `candidates.list?program_id=${this.program_id}`
      if(this.login.is_admin && this.login.role !== 'admin') {
        url += `&role=${this.login.role}`
      }

      const candidates = await this.$fetch('GET', url)

      if (candidates && candidates.error) return this.handleError(candidates.error)

      this.$set(this.$data, 'candidates', candidates)

    },

    handleError(error) {

      if (this.errored) return
      this.errored = true

      alert(error.message || 'An unknown error has occurred')
      this.$router.push({ name: 'programs' })

    },

    selectCandidate (candidate_id) {
      this.$set(this.$data, 'selected_candidate', this.candidates.find((c) => c.id === candidate_id))
    },

    unselectCandidate () {
      this.$set(this.$data, 'selected_candidate', null)
    },

    async onExportClick() {

      if (this.exporting) return

      this.exporting = true

      const response = await this.$fetchRaw('GET', `programs.export?id=${this.program_id}`)
      if (response && response.error) this.handleError(response.error)

      const blob = await response.blob()

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;

      const date = new Date();
      const year = date.getFullYear();

      let month = date.getMonth() + 1;
      let day = date.getDate();

      if (day < 10) { day = `0${day}` }
      if (month < 10) { month = `0${month}` }

      const formatted_date = `${year}_${month}_${day}`;

      const file_name = `CaRMs_UA_CIM_${formatted_date}`;

      a.download = `${file_name}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      this.exporting = false

    },

    async onAutocompleteClick() {
      if (this.autocompleting) return

      this.autocompleting = true

      const response = await this.$fetchRaw('POST', `programs.auto_complete?id=${this.program_id}`)
      if (response && response.error) this.handleError(response.error);

      this.fetchCandidates()

      this.autocompleting = false
    }

  },
  watch: {
    'login.role': {
      handler() {
        this.$set(this.$data, 'program', null)
        this.$set(this.$data, 'candidates', null)
        this.initialize()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
/deep/ .page-view__section
  padding-top: 0

.query-form
  top: 3rem
  position: sticky

.view__modal
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  z-index: 1000
  pointer-events: none
  .modal-content
    min-width: 25vw
    max-width: 90vw
    width: auto
  .modal > *
    pointer-events: auto

.button.is-primary
    background: white
    color: #4abae9
    font-size: 0.75rem
</style>

<template>
  <PageView class="view">

    <template #header>
      <h2 class="title is-4">Programs</h2>
    </template>

    <div class="columns">
      <div class="column">
        <AppTable :columns="['33%','37%','12%', '10%', '1%', '1%','6%']" class="is-hoverable">
          <template v-slot:header>
            <th>Match Name</th>
            <th>Program Name</th>
            <th>Program Code</th>
            <th>Review Type</th>
            <th align="center">#Candidates</th>
            <th align="center">#Reviews</th>
            <th></th>
          </template>
          <template v-if="programs && programs.length">
            <AppTableRow
              v-for="(program, index) in programs"
              :key="program.id"
              @click.native="viewProgram(program)"
            >
              <td>{{program.match}}</td>
              <td>{{program.name}}</td>
              <td>{{program.code}}</td>
              <td>{{ program.review_type }}</td>
              <td align="center">{{program.candidate_count}}/{{program.expected_candidate_count}}</td>
              <td align="center">{{program.completed_reviews_count}} / {{program.total_reviews_count}}</td>
              <template v-slot:controls>
                <div
                  v-if="login && login.role === 'admin'"
                  class="control"
                >
                  <button
                    class="button is-danger is-small is-rounded"
                    @click.stop="deleteProgram(program, index)"
                    :disabled="program.loading"
                  >
                    Delete
                  </button>
                </div>
                <div
                  v-if="login && login.role === 'admin'"
                  class="control"
                >
                  <button
                    class="button is-primary is-small is-rounded"
                    @click.stop="manageProgram(program)"
                    :disabled="program.loading"
                  >
                    Manage
                  </button>
                </div>
                <div
                  class="control"
                >
                  <button
                    class="button is-primary is-small is-rounded"
                    @click.stop="viewProgram(program)"
                    :disabled="program.loading"
                  >
                    View
                  </button>
                </div>
              </template>
            </AppTableRow>
          </template>
          <template v-else>
            <EmptyRow>Nothing to see here.</EmptyRow>
          </template>
        </AppTable>
        <template v-if="login && login.role === 'admin'">
          <router-link :to="{name: 'import'}" class="button is-primary is-rounded">Import New</router-link>
        </template>
      </div>
    </div>
  </PageView>
</template>

<script>
import { mapState } from 'vuex'
import AppTable from '@/components/AppTable.vue'
import AppTableRow from '@/components/AppTableRow.vue'
import EmptyRow from '@/components/EmptyRow.vue'
import PageView from '@/components/PageView.vue'

export default {
  name: 'ProgramsView',
  components: {
    AppTable,
    AppTableRow,
    EmptyRow,
    PageView
  },
  data() {
    return {
      loading: false,
      programs: null
    }
  },
  computed: {
    ...mapState({
      login: state => state.session && state.session.login
    })
  },
  created() {
    this.initialize()
  },
  methods: {

    initialize() {
      if (!this.programs) this.fetchPrograms()
    },

    async fetchPrograms() {

      this.loading = true

      let url = `programs.all`
      if(this.login.is_admin && this.login.role !== 'admin') {
        url += `?role=${this.login.role}`
      }

      const response = await this.$fetch('GET', url)
      this.$set(this.$data, 'programs', response)

      this.loading = false

    },

    async viewProgram(program) {

      if (program.loading) return

      this.$router.push({ name: 'program', params: { id: program.id } })

    },

    async deleteProgram(program, index) {

      if (program.loading) return

      if (!confirm(`Are you sure you want to delete the Program "${program.name}"`)) {
        return
      }

      program.loading = true

      const response = await this.$fetch('POST', `programs.delete?id=${program.id}`)

      this.$delete(this.programs, index)

    },

    async manageProgram(program) {

      if (program.loading) return

      this.$router.push({ name: 'manage-program', params: { id: program.id } })

    },

  },
  watch: {
    'login.role': {
      handler() {
        this.$set(this.$data, 'programs', null)
        this.initialize()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
</style>

<template>
  <PageView class="view">

    <template #header>
      <BackButton class="inline-button" />
      <h2 class="title is-4">Manage Users</h2>
    </template>

    <div class="columns">
      <div class="column">

        <template v-if="user">

          <form autocomplete="off">

            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input
                  :class="['input', { 'is-danger': !form.name.first && !form.name.valid }]"
                  type="text"
                  v-model="form.name.value"
                  @change="onFieldChange('name')"
                  :disabled="loading"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input
                  :class="['input', { 'is-danger': !form.email.first && !form.email.valid }]"
                  type="email"
                  v-model="form.email.value"
                  @change="onFieldChange('email')"
                  :disabled="loading"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Role</label>
              <div class="control">
                <div class="select">
                  <select
                    v-model="form.role.value"
                    :disabled="loading"
                  >
                    <option value="reviewer">Reviewer</option>
                    <option value="admin">Admin</option>
                  </select>
                </div>
              </div>
            </div>

            <br />
            <div class="field">
              <div class="control">
                <button
                  class="button is-primary is-rounded"
                  v-on:click.self.prevent
                  @click="onSubmitClicked"
                  :disabled="loading || !valid"
                >{{ submit_text }}</button>
                <p v-if="error" class="help is-danger">
                  {{error}}
                </p>
              </div>
            </div>

          </form>

        </template>
        <template v-else>
          LOADING
        </template>

      </div>
    </div>
  </PageView>
</template>

<script>
import AdminMixin from '@/mixins/admin'
import BackButton from '@/components/BackButton.vue'
import PageView from '@/components/PageView.vue'

export default {
  name: 'AdminUsersView',
  components: {
    BackButton,
    PageView
  },
  mixins: [AdminMixin],
  data() {
    return {
      loading: false,
      user: null,
      error: null,
      form: {
        name: {
          value: null,
          first: true,
          valid: false
        },
        email: {
          value: null,
          first: true,
          valid: false
        },
        role: {
          value: 'reviewer',
          valid: true
        }
      }
    }
  },
  computed: {
    user_id: function() {
      const user_id = parseInt(this.$route.params.user_id)
      return isNaN(user_id) ? null : user_id
    },
    valid: function() {
      const fields = Object.keys(this.form)
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (!this.form[field].valid) return false
      }
      return true
    },
    submit_text: function() {
      return this.user_id ? 'Update' : 'Create'
    }
  },
  created() {
    if (!this.user) {
      if (this.user_id) {
        this.fetchUser()
      } else {
        this.$set(this.$data, 'user', {})
      }
    }
  },
  methods: {

    async fetchUser() {

      const user = await this.$fetch('GET', `logins.get?id=${this.user_id}`)

      this.$set(this.$data, 'user', user)

      Object.keys(this.form).forEach((key) => {
        this.$set(this.form[key], 'value', user[key])
        this.$set(this.form[key], 'valid', true)
      })

    },

    onFieldChange(field) {
      this.form[field].first = false
      this.form[field].valid = !!this.form[field].value
    },

    async onSubmitClicked() {

      if (!this.valid) return

      this.loading = true

      const data = Object.keys(this.form).reduce((data, field) => {
        data[field] = this.form[field].value
        return data
      }, {})

      if (this.user_id) {
        const response = await this.$fetch('POST', `logins.update?id=${this.user_id}`, data)
        if (response === undefined) {
          // assume a 401 was handled by $fetch and we shouldn't continue
        } else if (response.error) {
          this.$set(this.$data, 'error', response.error.message)
          this.loading = false
        } else if (typeof response !== 'object') {
          this.$set(this.$data, 'error', 'An unknown error has occurred. Please try again.')
          this.loading = false
        } else {
          this.$router.push({ name: 'admin-users' })
        }
      } else {
        const response = await this.$fetch('POST', `logins.create`, data)
        if (response === undefined) {
          // assume a 401 was handled by $fetch and we shouldn't continue
        } else if (response.error) {
          this.$set(this.$data, 'error', response.error.message)
          this.loading = false
        } else if (typeof response !== 'object') {
          this.$set(this.$data, 'error', 'An unknown error has occurred. Please try again.')
          this.loading = false
        } else {
          this.$router.push({ name: 'admin-users' })
        }
      }

    }

  }
}
</script>

<style lang="sass" scoped>
/deep/ .page-view__section
  padding: 0

form
  padding: 2rem 0
  max-width: 1120px
  margin: 0 auto
</style>

<template>
  <tr class="empty-row">
    <td :colspan="colspan" align="center"><slot /></td>
  </tr>
</template>

<script>
export default {
  inject: [
    'table'
  ],

  computed: {
    colspan () {
      return this.table.columns ? this.table.columns.length : 1
    }
  }
}
</script>

<style lang="sass">
.empty-row td
  opacity: 0.5 !important
  padding: 3rem !important
</style>

<template>
  <div
    class="review-form-field field is-horizontal"
    :class="{ 'is-suggested': suggested }"
  >
    <div class="field-label">
      <label class="query-field__label label" :title="title">
        <span class="query-field__label-text"><slot /></span>
      </label>
    </div>
    <div class="field-body" style="flex-grow:1;">
      <div class="field is-grouped is-grouped-right">
        <span v-if="suggested" class="badge">Autofilled</span>

        <p class="review-form-field__range-control control">
          <input
            type="range"
            class="range-input is-small"
            :min="min_value"
            :max="max_value"
            :step="step_value"
            :value="value || dirty ? value : default_value"
            :disabled="disabled"
            @input="onInput"
            @blur="onBlur"
          />
        </p>
        <p class="review-form-field__value-control control">
          <input
            type="number"
            class="input is-small"
            :min="min_value"
            :max="max_value"
            :step="step_value"
            :value="value || dirty ? value : default_value"
            :disabled="disabled"
            @input="onInput"
            @blur="onBlur"
          />
        </p>
        <p v-if="$slots.info">
          <span class="review-form-field__info">
            <span class="review-form-field__info-icon">?</span>
            <span class="box review-form-field__info-bubble">
              <slot name="info" />
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean
    },
    suggested: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
    },
    min_value: {
      type: Number
    },
    max_value: {
      type: Number
    },
    step_value: {
      type: Number
    },
    weight_range: {
      type: Array,
      default: () => [],
    },
    default_value: {
      type: Number,
      default: 0,
    },
  },

  data () {
    return {
      previous_value: null,
      dirty: false,
    }
  },

  computed: {
    title: function() {
      const slot = this.$slots.default && this.$slots.default[0]
      return (slot.text && slot.text.trim()) || undefined
    }
  },

  methods: {
    onToggle: function() {
      this.$emit('toggle', this.disabled)
    },
    onInput: function(evt) {
      let value = evt.target.value
      value = isNaN(value) ? this.default_value : Number(value)

      value = value < this.min_value ? this.min_value : value > this.max_value ? this.max_value : value

      if (this.weight_range.length && !this.weight_range.includes(value)) {

        const up_value = this.weight_range.find((range_value) => {
          if (range_value >= value) return range_value
          return false
        })

        const down_value = this.weight_range.find((range_value) => {
          if (range_value <= value) return range_value
          return false
        })

        if (value <= this.previous_value) {
          value = down_value
        } else if (value >= this.previous_value) {
          value = up_value
        }

        if (
          evt.target.type === 'range' &&
          (this.previous_value === down_value ||
          this.previous_value === up_value)
        ) {
          value = this.previous_value
        }
      }

      const isEmptyString = evt.target.value === ''

      this.$emit('input', value)

      this.$nextTick(() => {
        if (isEmptyString) evt.target.value = ''
        else evt.target.value = value
      })

      this.previous_value = value
      this.dirty = true
    },
    onBlur: function(evt) {
      let value = evt.target.value
      value = isNaN(value) ? this.default_value : Number(value)
      this.$emit('input', value, evt)
      evt.target.value = value
      this.previous_value = value
      this.dirty = true
    }
  }
}
</script>

<style lang="sass">
@import "../../node_modules/bulma/sass/utilities/_all.sass"

.query-field__label
  text-align: left

.query-field__label-text

.review-form-field
  border-bottom: solid 1px #eee
  margin: 0 !important
  padding: 0.5rem !important
  &:hover
    background-color: #fafafa
  .field-label
    +tablet
      max-width: 40%
    +widescreen
      max-width: 50%

.review-form-field__label
  white-space: nowrap
  cursor: pointer
  overflow: hidden
  text-overflow: ellipsis
  input
    display: inline-block
    margin-right: 0.3rem
    &:not(:checked) ~ .review-form-field__label-text
      opacity: 0.6

.review-form-field__info
  display: inline-block
  position: relative
  &:hover
    .review-form-field__info-bubble
      opacity: 1
      transform: translateX(-1.5rem)

.review-form-field__info-icon
  display: inline-flex
  align-items: center
  justify-content: center
  width: 1.25em
  height: 1.25em
  background: #1a9ed5
  color: $white
  border-radius: 50%
  font-size: 13px
  font-weight: bold
  cursor: pointer

.review-form-field__info-bubble
  position: absolute
  top: 0
  right: 0
  z-index: 1
  font-weight: normal
  max-width: 400px
  min-width: 300px
  white-space: normal
  text-align: left
  font-size: 12px
  pointer-events: none
  opacity: 0
  transition: all 150ms

.review-form-field__range-control
  width: 4em
  display: flex
  .range-input
    width: 100%
    margin: auto

.review-form-field__value-control
  width: 3.5em
  .input
    text-align: center
</style>

<template>
  <table class="candidates table is-fullwidth is-striped is-hoverable">
    <colgroup>
      <template v-for="(field, key) in form">
        <col
          :key="key"
          :width="field.width"
        />
      </template>
    </colgroup>
    <thead>
      <tr>
        <template v-for="(field, key) in form">
          <th
            :key="key"
            @click="field.sortable && onHeaderClick(key)"
            style="cursor:pointer"
          >
            <span>{{ field.label }}</span>
            <template>
              <template v-if="descending">
                <span
                  class="sort-arrow-down"
                  :class="{ active: sort === key }"
                />
              </template>
              <template v-else>
                <span
                  class="sort-arrow-up"
                  :class="{ active: sort === key }"
                />
              </template>
            </template>
          </th>
        </template>
      </tr>
    </thead>
    <tbody>
      <template v-for="candidate in sorted_candidates">
        <Candidate
          :key="candidate.id"
          :candidate="candidate"
          :program_id="program_id"
          :show_details="selected_candidate_id === candidate.id"
          :form="form"
          :onCandidateClick="onCandidateClick"
        />
      </template>
    </tbody>
  </table>
</template>

<script>
import Candidate from '@/components/Candidate.vue'

export default {
  name: 'Candidates',
  components: {
    Candidate
  },
  props: {
    candidates: {
      type: Array
    },
    program_id: {
      type: Number
    }
  },
  data: function() {
    return {
      selected_candidate_id: null,
      form: {
        carms_id: {
          label: 'CaRMS ID',
          width: '15%',
          sortable: true
        },
        name: {
          label: 'Name',
          width: '15%',
          sortable: true
        },
        school: {
          label: 'School',
          width: '15%',
          sortable: true
        },
        total_score: {
          label: 'Total Score',
          width: '11%',
          sortable: true
        },
        avg_score: {
          label: 'Average Score',
          width: '11%',
          sortable: true
        },
        std_dev: {
          label: 'Standard Deviation',
          width: '11%',
          sortable: true
        },
        num_reviews: {
          label: "# Reviews",
          width: '8%',
          sortable: true
        },
        num_reviewers: {
          label: '# Reviewers',
          width: '8%',
          sortable: true
        },
        complete: {
          label: 'Complete',
          width: '11%',
          sortable: true
        }
      },
      review_field_list: {},
      sort: 'avg_score',
      descending: true
    }
  },
  computed: {
    sorted_candidates: function() {

      if (!this.candidates) return null

      const sorted_candidates = this.candidates.slice()

      sorted_candidates.sort((a, b) => {
        const value = (a[this.sort] < b[this.sort]) ? -1 : 1
        return this.descending ? -value : value
      })

      return sorted_candidates

    }
  },
  methods: {

    onHeaderClick(key) {
      this.$set(this.$data, 'sort', key)
      this.$set(this.$data, 'descending', !this.descending)
    },

    onCandidateClick(candidate_id) {
      if (this.selected_candidate_id == candidate_id) {
        this.selected_candidate_id = null
      } else {
        this.selected_candidate_id = candidate_id
      }
    }

  }
}
</script>

<template>
  <div class="review-form">
    <form class="form" @submit="onSubmit" @change="onChange" autocomplete="off">
      <slot name="fields" />
      <div class="review-form__controls field is-grouped is-grouped-centered">
        <slot name="controls" />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ReviewForm',
  methods: {
    onSubmit: function(evt) {
      evt.preventDefault()
      this.$emit('submit')
    },
    onChange (event) {
      this.$emit('change', event)
    },
  }
}
</script>

<style lang="sass">
.review-form__controls
  margin-top: 1rem
  > .control
    flex: 1 !important
</style>

<template>
  <PageView class="view">

    <template #header v-if="candidate && review">
      <BackButton class="inline-button" />

      <h2 class="navigation-title title is-4">
        Review: {{ candidate.name }} ({{ candidate.carms_id }}) - {{ candidate.school }}
      </h2>
      <h2 class="navigation-title" v-if="review.login_id !== login.id">
        (on behalf of {{ review.reviewer }})
      </h2>
    </template>

    <template v-if="program && candidate && review">

      <ReviewForm
        class="review-form"
        @submit="onReviewFormSubmitted"
      >
        <template v-slot:fields>

          <ReviewFormField
            v-for="field in form"
            v-model="field.value"
            :suggested="field.hasOwnProperty('suggested')"
            :min_value="field.min_value"
            :max_value="field.max_value"
            :step_value="field.step_value"
            :default_value="review.status === 'complete' ? field.value : field.suggested"
            :weight_range="field.weight_range"
            :key="field.key"
          >
            <span>{{ field.label }} ({{ field.min_value }} - {{ field.max_value }})</span>

            <template v-slot:info>
              <span v-html="field.description" />
              <p style="margin-top:10px;"><strong>{{ field.link }}</strong></p>
            </template>
          </ReviewFormField>
        </template>
        <br />
        <template v-slot:controls>
          <div class="control">
            <button
              class="button is-primary is-rounded"
              :disabled="loading"
              :class="{ 'is-loading': loading }"
            >
              Submit Review
            </button>
          </div>
        </template>
      </ReviewForm>
    </template>

    <template v-else>
      LOADING
    </template>

  </PageView>
</template>

<script>
import { mapState } from 'vuex'
import BackButton from '@/components/BackButton.vue'
import PageView from '@/components/PageView.vue'
import ReviewForm from '@/components/ReviewForm.vue'
import ReviewFormField from '@/components/ReviewFormField.vue'

export default {
  name: 'ReviewView',
  components: {
    BackButton,
    PageView,
    ReviewForm,
    ReviewFormField
  },
  data: function() {
    return {
      program: null,
      candidate: null,
      review: null,
      loading: false,
      errored: false,
      form: [],
    }
  },
  computed: {
    program_id: function() {
      return parseInt(this.$route.params.program_id)
    },
    candidate_id: function() {
      return parseInt(this.$route.params.candidate_id)
    },
    review_id: function() {
      return parseInt(this.$route.params.review_id)
    },
    ...mapState({
      login: state => state.session && state.session.login
    })
  },
  created() {
    this.initialize()
  },
  methods: {

    async initialize() {
      this.form = await this.$fetch('GET', 'programs.review_field_list?program_id=' + this.program_id);
      if (!this.program) this.fetchProgram()
      if (!this.candidate) this.fetchCandidate()
      if (!this.review) this.fetchReview()
    },

    async fetchProgram() {

      let url = `programs.get?id=${this.program_id}`
      if(this.login.is_admin && this.login.role !== 'admin') {
        url += `&role=${this.login.role}`
      }

      const program = await this.$fetch('GET', url)

      if (program.error) return this.handleError(program.error)

      this.$set(this.$data, 'program', program)

    },

    async fetchCandidate() {

      let url = `candidates.get?id=${this.candidate_id}`
      if(this.login.is_admin && this.login.role !== 'admin') {
        url += `&role=${this.login.role}`
      }

      const candidate = await this.$fetch('GET', url)

      if (candidate.error) return this.handleError(candidate.error)

      this.$set(this.$data, 'candidate', candidate)

    },

    async fetchReview() {

      let url = `reviews.get?candidate_id=${this.candidate_id}`
      if(this.review_id) {
        url += `&review_id=${this.review_id}`
      }
      if(this.login.is_admin && this.login.role !== 'admin') {
        url += `&role=${this.login.role}`
      }

      const review = await this.$fetch('GET', url)

      if (review.error) return this.handleError(review.error)
      console.log('review', review)

      this.$set(this.$data, 'review', review)

      this.form.forEach((field, index) => {
        this.$set(this.form[index], 'value', review[field.key] || 0)
        if(review.suggested.hasOwnProperty(field.key)) {
          this.$set(this.form[index], 'suggested', review.suggested[field.key])
        }
      })

      // Push all autofilled fields to the top
      this.form.sort((a,b) => Number('suggested' in b) - Number('suggested' in a))
    },

    async onReviewFormSubmitted() {

      const data = this.form.reduce((data, field) => {
        data[field.key] = field.value

        return data
      }, {})

      this.loading = true

      let url = `reviews.update?candidate_id=${this.candidate_id}`
      if(this.review_id) {
        url += `&review_id=${this.review_id}`
      }
      if(this.login.is_admin && this.login.role !== 'admin') {
        url += `&role=${this.login.role}`
      }

      await this.$fetch('POST', url, data)

      this.navigateToProgram()

    },

    handleError(error) {

      if (this.errored) return
      this.errored = true

      alert(error.message || 'An unknown error has occurred')
      this.navigateToProgram()

    },

    navigateToProgram() {
      this.$router.push({
        name: 'program',
        params: {
          id: this.program_id
        }
      })
    }

  },
  watch: {
    'login.role': {
      handler() {
        this.$set(this.$data, 'program', null)
        this.$set(this.$data, 'candidate', null)
        this.$set(this.$data, 'review', null)
        this.initialize()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
/deep/ .page-view__section
  max-width: 1160px
  margin: 0 auto
</style>

<template>
  <tr :class="{ 'candidate': true, 'is-selected': show_details }" @click="$emit('click')">
    <td :colspan="Object.keys(form).length" style="padding:0">
      <table style="width:100%">
        <colgroup>
          <template v-for="(field, key) in form">
            <col
              :key="key"
              :width="field.width"
            />
          </template>
        </colgroup>
        <tbody>
          <tr
            class="candidate__info"
            @click="onCandidateClick(candidate.id)"
          >
            <template v-for="(field, key) in form">
              <td
                :key="key"
              >
                <template v-if="key === 'avg_score'">
                  {{ +candidate.avg_score.toFixed(2) }}
                </template>
                <template v-else-if="key === 'std_dev'">
                  {{ +candidate.std_dev.toFixed(2) }}
                </template>
                <template v-else-if="key === 'num_reviews'">
                  {{ candidate.num_reviews }}
                </template>
                <template v-else-if="key === 'num_reviewers'">
                  {{ candidate.num_reviewers }}
                </template>
                <template v-else-if="key === 'complete'">
                  <span v-if="candidate[key]" class="tag is-success">Completed</span>
                  <span v-else class="tag is-danger">Incomplete</span>
                </template>
                <template v-else>
                  {{ candidate[key] }}
                </template>
              </td>
            </template>
          </tr>
          <tr v-if="show_details" class="candidate__details">
            <td
              :colspan="Object.keys(form).length"
              style="max-width:0;position:relative;"
            >
              <template v-if="candidate.reviews && candidate.reviews.length">
                <Reviews
                  :program_id="program_id"
                  :reviews="candidate.reviews"
                />
              </template>
              <template v-else>
                NO ASSIGNED REVIEWERS
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>
</template>

<script>
import Reviews from '@/components/Reviews.vue'

export default {
  name: 'Candidate',
  components: {
    Reviews
  },
  props: {
    program_id: {
      type: Number
    },
    candidate: {
      type: Object
    },
    show_details: {
      type: Boolean
    },
    form: {
      type: Object
    },
    onCandidateClick: {
      type: Function
    },
  }
}
</script>

<style lang="sass">
.candidate
  cursor: pointer
  &.is-score-low
    th, td
      color: #ef6c57
  &.is-score-high // etc
    th, td
      color: #70b943

.candidate__info
  background-color: transparent !important
  th, td
    border: none

.candidate__details
  background-color: transparent !important
  th, td
    border: none
    padding-top: 0
    font-size: 14px
</style>

<template>
  <PageView class="view">
    <template #header>
      <h2 class="title is-4">Login</h2>
    </template>

    <div class="login-view__inner">
      <LoginForm
        class="login-form"
        @logged-in="onLoggedIn"
      />
    </div>

    <div class="privacy">
      <div class="privacy-notice">
        <p class="privacy-description">
          <strong>Protection of Privacy</strong> - Personal information provided through this application is collected in accordance with Section 33(c) of the Alberta Freedom of Information and Protection of Privacy Act (the FOIP Act) and will be protected under Part 2 of that Act. It will be used for the sole purpose of CaRMS Resident Applicant Assessment. Should you require further information about collection, use and disclosure of personal information, please contact:
        </p>
        <p>Department of Medicine Medical Education Office</p>
        <p>13-132 CSB</p>
        <p>11350-83 Avenue</p>
        <p>Edmonton, AB T6G 2G3</p>
        <p>Tel 780-248-1343</p>
        <a href="mailto:medtmld@ualberta.ca">medtmld@ualberta.ca</a>
      </div>
    </div>
  </PageView>
</template>

<script>
import PageView from '@/components/PageView.vue'
import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'Login',
  components: {
    PageView,
    LoginForm
  },
  methods: {
    onLoggedIn: function(session) {
      this.$store.dispatch('setSession', session)
      this.$router.push({ name: 'programs' })
    }
  }
}
</script>

<style lang="sass" scoped>
/deep/ .page-view__section
  display: flex
  flex-direction: column
  padding: 0

.footer
  display: none

.login-view__inner
  display: flex
  flex: 1
  padding: 20px

.login-form
  margin: auto

.privacy
  display: flex
  flex: 1
  align-self: flex-end
  background: #fafafa
  width: 100%
  padding: 30px 20px

.privacy-notice
  margin: 0 auto
  max-width: 620px
  font-size: 12px

.privacy-description
  margin-bottom: 20px
</style>

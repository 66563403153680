<template>
  <div class="login-form">
    <h3 class="title has-text-black" style="text-align:center;">Security Question</h3>
    <p class="subtitle">{{security_question.question}}</p>
    <form autocomplete="off">
      <div class="field">
        <div class="control">
          <input
            class="input"
            type="text"
            autofocus=""
            :disabled="loading"
            v-model="form.answer"
          />
        </div>
      </div>
      <button
        class="button is-block is-info is-fullwidth is-rounded"
        @click="onSubmitClicked"
        :disabled="loading || invalid"
        :class="{ 'is-loading': loading }"
      >
        Submit
      </button>
      <p v-if="error" class="help is-danger" style="text-align:center;">
        {{error}}
      </p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ImportSecurityQuestionForm',
  props: [
    'session_id',
    'security_question'
  ],
  data: function() {
    return {
      form: {
        answer: ''
      },
      loading: false,
      error: null
    }
  },
  computed: {
    invalid: function() {
      return !(this.form.answer)
    }
  },
  methods: {
    trySubmit: async function() {
      try {

        const response = await this.$fetch('POST', 'import.security_question', {
          session_id: this.session_id,
          question_id: this.security_question.question_id,
          user_id: this.security_question.user_id,
          answer: this.form.answer
        })

        if (response.success) {
          this.$emit('answered')
          return false
        } else {
          return 'There was an error with your Security Question. Please try again.'
        }

      } catch(e) {
        return e
      }
    },
    onSubmitClicked: async function() {

      this.error = null
      this.loading = true

      const error = await this.trySubmit()
      if (error) this.$set(this.$data, 'error', error)

      this.loading = false

    }
  }
}
</script>

<template>
  <div id="app">

    <AppNav :signed-in="!!session" @sign-out="onLogoutClick">
      <template v-slot:links>
        <router-link :to="{ name: 'programs' }" class="navbar-item">
          Programs
        </router-link>
        <template v-if="login && login.role === 'admin'">
          <router-link :to="{ name: 'import' }" class="navbar-item">
            Import
          </router-link>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
              Admin
            </a>
            <div class="navbar-dropdown is-boxed">
              <router-link :to="{ name: 'admin-users' }" class="navbar-item">
                Manage Users
              </router-link>
            </div>
          </div>
        </template>
      </template>
      <template v-slot:session>
        <template v-if="session && login">
          <div>
            <router-link :to="{ name: 'update-password' }" style="color:white;">
              {{ login.name }} ({{ login.email }})
            </router-link>
            <select v-if="login.is_admin" v-model="login.role" style="margin-left:5px;">
              <option value="admin">Admin</option>
              <option value="reviewer">Reviewer</option>
            </select>
          </div>
        </template>
        <template v-else>
          Please sign in.
        </template>
      </template>
    </AppNav>

    <main class="app__content">
      <transition name="app-view-changed" mode="out-in" :appear="true">
        <router-view v-if="appReady"/>
      </transition>
    </main>

    <!--AppFooter>
      <template v-if="session">
        <p v-if="login">You are logged in as {{ login.name }} ({{ login.email }}).</p>
        <p><a @click="onLogoutClick">Sign Out</a></p>
      </template>
      <template v-else>
        <p>Please sign in.</p>
      </template>
    </AppFooter-->

  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppNav from '@/components/AppNav.vue'
// import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'App',
  components: {
    AppNav,
    // AppFooter
  },
  data () {
    return {
      appReady: false
    }
  },
  computed: {
    ...mapState({
      login: state => state.session && state.session.login,
      session: state => state.session && state.session.session_token
    })
  },
  created: function() {
    this.$store.dispatch('checkCachedSession')
    this.authRedirect()
    this.appReady = true
  },
  methods: {
    authRedirect() {
      if (!this.session) {
        if (['login', 'forgot-password', 'reset-password'].includes(this.$route.name)) return
        return this.$router.replace({ name: 'login' })
      }
    },
    onLogoutClick() {
      this.$store.dispatch('clearSession')
      this.authRedirect()
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.authRedirect()
      }
    }
  }
}
</script>

<style lang="sass">
@charset "utf-8"

@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700&display=swap')

// Brand colors
$bright-blue: #1a9ed5
$dark-purple: #5e3962
$orange: #f38236

// Update Bulma's global variables
$family-sans-serif: "Ubuntu", sans-serif
$primary: $bright-blue
$link: $bright-blue
$widescreen-enabled: true
$fullhd-enabled: false

.button
  font-weight: 600
  text-transform: uppercase

// Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass"
@import "../node_modules/bulma/sass/base/_all.sass"
@import "../node_modules/bulma/sass/elements/box.sass"
@import "../node_modules/bulma/sass/elements/button.sass"
@import "../node_modules/bulma/sass/elements/container.sass"
@import "../node_modules/bulma/sass/elements/table.sass"
@import "../node_modules/bulma/sass/elements/tag.sass"
@import "../node_modules/bulma/sass/elements/title.sass"
@import "../node_modules/bulma/sass/elements/progress.sass"
@import "../node_modules/bulma/sass/form/_all.sass"
@import "../node_modules/bulma/sass/grid/columns.sass"
@import "../node_modules/bulma/sass/components/breadcrumb.sass"
@import "../node_modules/bulma/sass/components/modal.sass"
@import "../node_modules/bulma/sass/components/navbar.sass"
@import "../node_modules/bulma/sass/layout/hero.sass"
@import "../node_modules/bulma/sass/layout/section.sass"
@import "../node_modules/bulma/sass/layout/footer.sass"

.badge
  background: $primary
  color: white
  font-weight: bold
  padding: 0px 7px 0px 7px
  border-radius: 4px
  margin-right: 10px
  position: relative
  top: 2px
  font-size: 12px
  line-height: 30px

// Global styles
html
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

body
  min-height: 100vh

// App component styles
#app
  display: flex
  min-height: 100vh
  flex-direction: column

.app__content
  flex: 1
  display: flex
  position: relative

.app-view-changed-enter-active,
.app-view-changed-leave-active
  transition: opacity 200ms ease

.app-view-changed-enter,
.app-view-changed-leave-to
  opacity: 0

.sort-arrow-up,
.sort-arrow-down
  display: inline-flex
  // width: 0.8rem
  // height: 0.8rem
  vertical-align: middle
  margin-left: 0.25rem
  opacity: 0.3
  &.active
    opacity: 1

.sort-arrow-up
  border: solid 8px transparent
  border-bottom-color: $primary
  transform: translateY(-5px) scaleX(0.75)

.sort-arrow-down
  border: solid 8px transparent
  border-top-color: $primary
  transform: translateY(4px) scaleX(0.75)

.row
  display: flex
  flex-wrap: wrap

.select-toggle
  color: $primary
  margin-right: 5px
  margin-left: 5px
  cursor: pointer
  &:first-of-type
    margin-left: 0
  &:last-of-type
    margin-right: 0

.cell
  flex: 1

.view
  position: relative
  max-width: 100%

.navbar
  position: sticky
  top: 0
</style>

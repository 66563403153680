<template>
  <PageView class="view">

    <template #header>
      <h2 class="title is-4">Import</h2>
    </template>

    <template v-if="!session_id">
      <div class="login-view__inner">
        <ImportLoginForm
          class="login-form"
          @logged-in="onLoggedIn"
        />
      </div>
    </template>

    <template v-if="security_question">
      <div class="login-view__inner">
        <ImportSecurityQuestionForm
          class="login-form"
          :session_id="session_id"
          :security_question="security_question"
          @answered="onSecurityQuestionAnswered"
        />
      </div>
    </template>

    <template v-if="matches && !match_id">
      <Breadcrumbs :back="() => { $router.push('/') }">
        Matches
      </Breadcrumbs>
      <AppTable :columns="['10%','70%','20%']">
        <template v-slot:header>
          <th align="center">Match ID</th>
          <th>Match Name</th>
          <th></th>
        </template>
        <template v-if="matches.length">
          <AppTableRow
            v-for="match in matches"
            :key="match.id"
          >
            <td align="center">{{match.id}}</td>
            <td>{{match.name}}</td>
            <template v-slot:controls>
              <button class="button is-small is-rounded is-primary" @click="setMatch(match.id)">Select</button>
            </template>
          </AppTableRow>
        </template>
        <template v-else>
          <EmptyRow>No matches found.</EmptyRow>
        </template>
      </AppTable>
    </template>

    <template v-if="programs && !program_id">
      <Breadcrumbs :back="() => { setMatch(null) }">
        Programs
      </Breadcrumbs>
      <AppTable :columns="['69%','15%','3%','13%']">
        <template v-slot:header>
          <th>Program Name</th>
          <th>Program Code</th>
          <th align="center">Applicants</th>
          <th></th>
        </template>
        <template v-if="programs.length">
          <AppTableRow
            v-for="program in programs"
            :key="program.id"
          >
            <td>{{program.name}}</td>
            <td>{{program.code}}</td>
            <td align="center">{{program.candidate_count}}</td>
            <template v-slot:controls>
              <button class="button is-small is-rounded is-primary" @click="setProgram(program.id)">Import</button>
            </template>
          </AppTableRow>
        </template>
        <template v-else>
          <EmptyRow>No programs found.</EmptyRow>
        </template>
      </AppTable>
    </template>

    <template v-if="program_id && !review_type">
      <Breadcrumbs :back="() => { setProgram(null) }">
        Programs
      </Breadcrumbs>
      <!-- Make this dynamic -->
      <div class="login-view__inner">
        <div class="review-type-form">
          <h3 class="title has-text-black" style="text-align:center;">Review Type</h3>
          <p class="subtitle">Select review type for the chosen program.</p>
          <template v-for="type in review_types">
            <button :key="type" class="button is-fullwidth is-rounder is-primary" @click="setReviewType(type)">{{type}}</button>
          </template>
        </div>
      </div>
    </template>

    <template v-if="review_type">
      <div class="login-view__inner" style="flex-direction:column;max-width:350px;margin:auto">
        <template v-if="program">
          <template v-if="error">
            <h4 class="title is-5" style="text-align:center;">Import error</h4>
            <p class="help is-danger">
              {{error}}
            </p>
          </template>
          <template v-else-if="program.status === 'success'">
            <h4 class="title is-5" style="text-align:center;">Import successful!</h4>
            <router-link class="button is-primary is-rounded" :to="`programs/${program.id}`">View</router-link>
          </template>
          <template v-else>
            <h4 class="title is-5" style="text-align:center;">Importing...</h4>
            <p class="subtitle is-5" style="text-align:center;">
              {{program.candidate_count}} / {{program.expected_candidate_count}}
              ({{import_percent}}%)
            </p>
            <progress
              class="progress is-primary"
              :value="program.candidate_count"
              :max="program.expected_candidate_count"
            />
          </template>
        </template>
      </div>
    </template>

  </PageView>
</template>

<script>
import AdminMixin from '@/mixins/admin'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import AppTable from '@/components/AppTable.vue'
import AppTableRow from '@/components/AppTableRow.vue'
import EmptyRow from '@/components/EmptyRow.vue'
import PageView from '@/components/PageView.vue'
import ImportLoginForm from '@/components/ImportLoginForm.vue'
import ImportSecurityQuestionForm from '@/components/ImportSecurityQuestionForm.vue'

export default {
  name: 'Login',
  components: {
    Breadcrumbs,
    AppTable,
    AppTableRow,
    EmptyRow,
    PageView,
    ImportLoginForm,
    ImportSecurityQuestionForm
  },
  mixins: [AdminMixin],
  data() {
    return {
      session_id: null,
      security_question: null,
      matches: null,
      match_id: null,
      programs: null,
      program_id: null,
      program: null,
      review_types: [],
      review_type: null,
      error: null
    }
  },
  computed: {
    import_percent: function() {
      if (!this.program) return
      return (this.program.candidate_count / this.program.expected_candidate_count * 100).toFixed(0)
    }
  },
  created() {
    this.authCheck()
  },
  methods: {

    authCheck() {
      if(!this.login || this.login.role !== 'admin') {
        this.$router.push({ name: 'programs' })
        return false
      }
      return true
    },

    onLoggedIn(data) {
      this.$set(this.$data, 'session_id', data.session_id)
      if(data.security_question) {
        this.$set(this.$data, 'security_question', data.security_question)
      } else {
        this.fetchMatches()
      }
    },

    onSecurityQuestionAnswered() {
      this.$set(this.$data, 'security_question', null)
      this.fetchMatches()
    },

    async fetchMatches() {
      const response = await this.$fetch('POST', 'import.matches', {
        session_id: this.session_id
      })
      this.$set(this.$data, 'matches', response)
    },

    setMatch(match_id) {
      this.$set(this.$data, 'match_id', match_id)
      this.fetchPrograms()
    },

    async fetchPrograms() {

      if (!this.match_id) {
        this.$set(this.$data, 'programs', null)
        return
      }

      const response = await this.$fetch('POST', 'import.programs', {
        session_id: this.session_id,
        match_id: this.match_id
      })
      this.$set(this.$data, 'programs', response)

    },

    setProgram(program_id) {
      this.$set(this.$data, 'program_id', program_id)
      this.fetchReviewTypes()
    },

    async fetchReviewTypes() {
      const response = await this.$fetch('GET', 'programs.review_types', )
      this.$set(this.$data, 'review_types', response)
    },

    setReviewType(review_type) {
      this.$set(this.$data, 'review_type', review_type)
      this.startImport()
    },

    async startImport() {

      const response = await this.$fetch('POST', 'import.start', {
        session_id: this.session_id,
        match_id: this.match_id,
        program_id: this.program_id,
        review_type: this.review_type,
      })

      if (response) {
        this.$set(this.$data, 'program', response)
        this.pollImport()
      } else {
        this.$set(this.$data, 'error', 'Something went wrong. Please try again.')
      }

    },

    async pollImport() {

      if (this.program.status === 'success') return

      setTimeout(async () => {

        const response = await this.$fetch('GET', `programs.get?id=${this.program.id}`)

        if (response) {
          this.$set(this.$data, 'program', response)
          this.pollImport()
        } else {
          this.$set(this.$data, 'error', 'Something went wrong. Please try again.')
        }

      }, 1000)

    }

  },
  watch: {
    'login.role': {
      handler() {
        this.authCheck()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.login-view__inner
  display: flex
  height: 100%

.review-type-form
  margin: auto

.review-type-form button
  padding: 0.5rem
  margin: 0.5rem

.login-form
  margin: auto
</style>

<template>
  <div style="overflow-x:scroll">
    <table class="reviews table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr style="cursor:default;">
          <template v-for="field in form">
            <th
              :key="field.key"
              style="border:1px solid;"
            >
              <span>{{ field.label }}</span>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="review in sorted_reviews">
          <Review
            :key="review.id"
            :program_id="program_id"
            :review="review"
            :form="form"
          />
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Review from '@/components/Review.vue'

export default {
  name: 'Reviews',
  components: {
    Review
  },
  props: {
    program_id: {
      type: Number
    },
    reviews: {
      type: Array
    },
  },
  created() {
    this.form = [
      {
        key: 'reviewer',
        label: 'Reviewer',
      },
      {
        key: 'total_score',
        label: 'Total Score',
      },
      {
        key: 'avg_diff',
        label: 'Difference from Average',
      },
    ].concat(this.review_field_list);
  },
  computed: {
    sorted_reviews: function() {

      if (!this.reviews) return null

      const sorted_reviews = this.reviews.slice()

      sorted_reviews.sort((a, b) => {
        if(a.status === b.status) {
          return a.reviewer < b.reviewer ? -1 : 1
        } else {
          return a.status === 'complete' ? -1 : 1
        }
      })

      return sorted_reviews

    },
    ...mapState({
      review_field_list: state => state.review_field_list
    })
  }
}
</script>
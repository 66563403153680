<template>
  <div class="login-form">
    <h3 class="title has-text-black">Reset Password</h3>
    <form autocomplete="off">
      <div class="field">
        <div class="control">
          <input
            class="input"
            type="password"
            placeholder="New Password"
            :disabled="loading"
            v-model="form.new_password"
          />
        </div>
      </div>
      <div class="field">
        <div class="control">
          <input
            class="input"
            type="password"
            placeholder="Repeat New Password"
            :disabled="loading"
            v-model="form.repeat_password"
          />
        </div>
      </div>
      <button
        class="button is-block is-primary is-fullwidth is-rounded"
        @click="onResetPasswordClicked"
        :disabled="loading || invalid"
        :class="{ 'is-loading': loading }"
      >
        Reset Password
      </button>
      <p v-if="error" class="help is-danger">
        {{error}}
      </p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ResetPasswordForm',
  props: {
    code: {
      type: String
    }
  },
  data: function() {
    return {
      form: {
        new_password: '',
        repeat_password: ''
      },
      loading: false,
      error: null
    }
  },
  computed: {
    invalid: function() {
      return !(this.form.new_password && this.form.repeat_password) ||
        this.form.new_password !== this.form.repeat_password
    }
  },
  methods: {
    tryResetPassword: async function() {
      try {

        const response = await this.$fetch('POST', `password_resets.redeem?code=${this.code}`, this.form)

        if (response.error) {
          return response.error.message
        } else if(typeof response !== 'object') {
          return 'An unknown error has occurred. Please try again.'
        }

        this.$emit('password-reset')

        return false

      } catch(e) {
        return e
      }
    },
    onResetPasswordClicked: async function() {

      this.error = null
      this.loading = true

      const error = await this.tryResetPassword()
      if (error) this.$set(this.$data, 'error', error)

      this.loading = false
    }
  }
}
</script>

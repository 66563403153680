<template>
  <div class="login-form">
    <h3 class="title has-text-black" style="text-align:center;">CaRMS Sign In</h3>
    <p class="subtitle">Please sign in to CaRMS to proceed.</p>
    <form autocomplete="off">
      <div class="field">
        <div class="control">
          <input
            class="input"
            type="text"
            name="carms_username"
            placeholder="Your CaRMS Username / Email"
            autofocus=""
            :disabled="loading"
            v-model="form.username"
          />
        </div>
      </div>
      <div class="field">
        <div class="control">
          <input
            class="input"
            type="password"
            name="carms_password"
            placeholder="Your CaRMS Password"
            :disabled="loading"
            v-model="form.password"
          />
        </div>
      </div>
      <div class="field is-grouped">
        <p class="control" style="flex:1">
          <a
            class="button is-fullwidth is-rounded"
            @click="$router.push('/')"
            :disabled="loading"
          >
            Back
          </a>
        </p>
        <p class="control" style="flex:1">
          <button
            class="button is-fullwidth is-primary is-rounded"
            @click="onLoginClicked"
            :disabled="loading || invalid"
          >
            Sign In
          </button>
        </p>
      </div>
      <p v-if="error" class="help is-danger" style="text-align:center;">
        {{error}}
      </p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ImportLoginForm',
  data: function() {
    return {
      form: {
        username: '',
        password: ''
      },
      loading: false,
      error: null
    }
  },
  computed: {
    invalid: function() {
      return !(this.form.username && this.form.password)
    }
  },
  methods: {
    tryLogin: async function() {
      try {

        const response = await this.$fetch('POST', 'import.login', this.form)

        if (response.success) {
          this.$emit('logged-in', response)
          return false
        } else {
          return 'There was an error signing in. Please try again.'
        }

      } catch(e) {
        return e
      }
    },
    onLoginClicked: async function() {

      this.error = null
      this.loading = true

      const error = await this.tryLogin()
      if (error) this.$set(this.$data, 'error', error)

      this.loading = false

    }
  }
}
</script>

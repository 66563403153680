<template>
  <PageView class="view">

    <template #header>
      <BackButton class="inline-button" />
      <h2 class="title is-4">Manage Users</h2>
    </template>

    <div class="columns">
      <div class="column">
        <AppTable>
          <template v-slot:header>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Active</th>
            <th></th>
          </template>
          <template v-if="users && users.length">
            <AppTableRow
              v-for="user in users"
              :key="user.id"
            >
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.role }}</td>
              <td>
                <span v-if="user.active" class="tag is-primary">Active</span>
                <span v-else class="tag is-light">Not Active</span>
              </td>
              <template v-slot:controls>
                <div class="control" v-if="user.active">
                  <button
                    class="button is-danger is-small is-rounded"
                    @click="deactivateUser(user)"
                    :disabled="user.loading"
                  >Deactivate</button>
                </div>
                <div class="control" v-if="!user.active">
                  <button
                    class="button is-small is-rounded"
                    @click="reactivateUser(user)"
                    :disabled="user.loading"
                  >Reactivate</button>
                </div>
                <div class="control">
                  <button
                    class="button is-primary is-small is-rounded"
                    @click="editUser(user)"
                    :disabled="user.loading"
                  >Edit</button>
                </div>
              </template>
            </AppTableRow>
          </template>
          <template v-else>
            <EmptyRow>Nothing to see here.</EmptyRow>
          </template>
        </AppTable>

        <div class="control">
          <button
            class="button is-primary is-rounded"
            @click="$router.push({ name: 'admin-users-create' })"
          >Create New</button>
        </div>

      </div>
    </div>
  </PageView>
</template>

<script>
import AdminMixin from '@/mixins/admin'
import AppTable from '@/components/AppTable.vue'
import AppTableRow from '@/components/AppTableRow.vue'
import EmptyRow from '@/components/EmptyRow.vue'
import BackButton from '@/components/BackButton.vue'
import PageView from '@/components/PageView.vue'

export default {
  name: 'AdminUsersView',
  components: {
    AppTable,
    AppTableRow,
    EmptyRow,
    BackButton,
    PageView
  },
  mixins: [AdminMixin],
  data() {
    return {
      loading: false,
      users: null
    }
  },
  created() {
    if (!this.users) this.fetchUsers()
  },
  methods: {

    async fetchUsers() {

      this.loading = true

      const users = await this.$fetch('GET', 'logins.all')
      this.$set(this.$data, 'users', users)

      this.loading = false

    },

    async editUser(user) {

      if (user.loading) return

      this.$router.push({ name: 'admin-users-edit', params: { user_id: user.id } })

    },

    async deactivateUser(user) {

      if (user.loading) return

      if (!confirm(`Are you sure you want to deactivate the User "${user.email}"?`)) {
        return
      }

      user.loading = true

      const response = await this.$fetch('POST', `logins.deactivate?id=${user.id}`)

      user.active = false
      user.loading = false

    },

    async reactivateUser(user) {

      if (user.loading) return

      if (!confirm(`Are you sure you want to reactivate the User "${user.email}"?`)) {
        return
      }

      user.loading = true

      const response = await this.$fetch('POST', `logins.reactivate?id=${user.id}`)

      user.active = true
      user.loading = false

    }

  }
}
</script>

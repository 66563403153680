export default function (Vue) {
  Vue.prototype.$fetch = async function (method, path, payload) {

    const response = await this.$fetchRaw(method, path, payload)
    if (!response) return

    const data = await response.json()

    return data

  },
  Vue.prototype.$fetchRaw = async function (method, path, payload) {

    const options = {
      method: method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    }

    if (payload) {
      options.body = JSON.stringify(payload)
    }

    if (this.$store.state.session) {
      options.headers['Authorization'] = `Bearer ${this.$store.state.session.session_token}`
    }

    // const response = await fetch(`https://api.imraas.ca/api/${path}`, options)
    const response = await fetch(`/api/${path}`, options)

    if (response.status === 401) {
      this.$store.dispatch('clearSession')
      this.$router.replace({ name: 'login' })
      return
    }

    return response

  }
}

<template>
  <table class="table is-fullwidth app-table">
    <colgroup v-if="columns">
      <col v-for="(col, i) in columns" :key="i" :width="col" />
    </colgroup>
    <thead>
      <tr>
        <slot name="header" />
      </tr>
    </thead>
    <tbody>
      <slot />
    </tbody>
  </table>
</template>

<script>
export default {
  provide () {
    return {
      table: this
    }
  },

  props: {
    columns: {
      type: Array
    }
  }
}
</script>

<template>
  <div class="review">
    <div class="review__inner">
      <template v-if="review.status === 'complete'">
        <div class="review__header">
          <p class="review__header-label">
            <small>Reviewed by</small>
          </p>
          <p class="review__header-reviewer">
            <strong>{{ review.reviewer }}</strong>
          </p>
          <p class="review__header-score">
            <a v-if="!expanded" @click="expand"><small>Expand</small></a>
            <a v-else @click="collapse"><small>Collapse</small></a>
            <span class="tag is-primary" title="Total Score (Difference from Average)">{{ review.total_score }} ({{ review.avg_diff | plusMinus }})</span>
            <button class="button is-small is-rounded" @click="editReview()">
              Edit
            </button>
          </p>
        </div>
        <div class="review__content" v-if="expanded">
          <ul class="review__list">
            <template v-for="(field, key) in filtered_form">
              <li :key="key" :title="filtered_form[key].description || filtered_form[key].label">
                <strong>{{ field.label }}</strong>
                <span>{{ review[key] }}</span>
              </li>
            </template>
          </ul>
        </div>
      </template>
      <template v-else>
        <div class="review__header">
          <p class="review__header-label">
            <small>Pending review by</small>
          </p>
          <p class="review__header-reviewer">
            <strong>{{ review.reviewer }}</strong>
          </p>
          <p class="review__header-score">
            <button class="button is-small is-rounded" @click="editReview()">
              Edit
            </button>
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewModal',
  props: {
    program_id: {
      type: Number
    },
    review: {
      type: Object
    },
    form: {
      type: Object
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  filters: {
    plusMinus (value) {
      value = Number(value)
      if (value >= 0) return '+' + (+value.toFixed(2))
      else return (+value.toFixed(2))
    }
  },
  computed: {
    filtered_form () {
      const filtered = [ 'reviewer', 'total_score', 'avg_diff' ]
      return Object.keys(this.form).reduce((acc, key) => {
        if (filtered.includes(key)) return acc
        acc[key] = this.form[key]
        return acc
      }, {})
    }
  },
  methods: {
    expand () {
      this.$emit('expanded')
      this.$nextTick(() => this.expanded = true)
    },
    collapse () {
      this.expanded = false
    },
    editReview(review_id) {
      this.$router.push({
        name: 'review-id',
        params: {
          program_id: this.program_id,
          candidate_id: this.review.candidate_id,
          review_id: this.review.id
        }
      })
    }
  }
}
</script>

<style lang="sass">
.review
  margin: 1rem 0
  &:first-of-type
    margin-top: 0
  &:last-of-type
    margin-bottom: 0

.review__header
  position: relative

.review__header-score
  position: absolute
  top: 50%
  right: 0
  transform: translateY(-50%)
  a
    display: inline-flex
    margin-right: 1rem

.review__content
  font-size: 0.8rem
  margin-top: 0.5rem
  padding-top: 0.5rem
  border-top: solid 1px #eee
  li
    padding: 1px 4px
    border-radius: 3px
  li:hover
    background: #fafafa
  li span
    float: right
</style>

<template>
  <PageView class="view">

    <template #header>
      <h2 class="title is-4">Update Password</h2>
    </template>

    <div class="login-view__inner">

      <div v-if="success" style="width:100%;text-align:center;">
        <p>Your password has been updated successfully!</p>
      </div>

      <UpdatePasswordForm
        v-else
        class="login-form"
        @submitted="onSubmitted"
      />

    </div>
  </PageView>
</template>

<script>
import PageView from '@/components/PageView.vue'
import UpdatePasswordForm from '@/components/UpdatePasswordForm.vue'

export default {
  name: 'UpdatePassword',
  components: {
    PageView,
    UpdatePasswordForm
  },
  data: function() {
    return {
      success: false
    }
  },
  methods: {
    onSubmitted: function() {
      this.success = true
    }
  }
}
</script>

<style lang="sass" scoped>
.login-view__inner
  display: flex
  height: 100%

.login-form
  margin: auto
</style>

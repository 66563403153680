<template>
  <nav class="app-nav navbar is-dark">
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item">
        <strong>RaaS</strong>
      </router-link>
    </div>

    <div v-if="signedIn" class="navbar-menu">
      <div class="navbar-start">
        <slot name="links" />
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <small>
            <slot name="session" />
          </small>
        </div>
        <a v-if="signedIn" class="navbar-item" @click="$emit('sign-out')">
          Sign Out
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    signedIn: {
      type: Boolean
    }
  }
}
</script>

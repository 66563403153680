import { mapState } from 'vuex'

export default {
  created() {
    this.authCheck()
  },
  computed: {
    ...mapState({
      login: state => state.session && state.session.login
    })
  },
  methods: {
    authCheck() {
      if(!this.login || this.login.role !== 'admin') {
        this.$router.push({ name: 'programs' })
      }
    }
  },
  watch: {
    'login.role': {
      handler() {
        this.authCheck()
      }
    }
  }
}
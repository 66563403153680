<template>
  <tr @click="onReviewClick">
    <template v-if="review.status === 'complete'">
      <template v-for="field in form">
        <td 
          :key="field.key"
          style="border:1px solid;"
        >
          <template v-if="field.key === 'avg_diff'">
            {{ review.avg_diff | plusMinus }}
          </template>
          <template v-else>
            {{ review[field.key] }}
          </template>
        </td>
      </template>
    </template>
    <template v-else>
      <td 
        style="border:1px solid;"
      >
        {{ review.reviewer }}
      </td>
      <td 
        style="border:1px solid;" 
        :colspan="form.length - 1"
      >
        Review Incomplete
      </td>
    </template>
  </tr>
</template>

<script>
export default {
  name: 'Review',
  props: {
    program_id: {
      type: Number
    },
    review: {
      type: Object
    },
    form: {
      type: Array
    }
  },
  filters: {
    plusMinus (value) {
      value = Number(value)
      if (value >= 0) return '+' + (+value.toFixed(2))
      else return (+value.toFixed(2))
    }
  },
  methods: {
    onReviewClick() {
      this.$router.push({
        name: 'review-id',
        params: {
          program_id: this.program_id,
          candidate_id: this.review.candidate_id,
          review_id: this.review.id
        }
      })
    }
  }
}
</script>
<template>
  <div class="login-form">
    <h3 class="title has-text-black" style="text-align:center;">Forgot Password</h3>
    <p class="subtitle">Please enter your email address and we will send you instructions on how to reset your password</p>
    <form autocomplete="off">
      <div class="field">
        <div class="control">
          <input
            class="input"
            type="email"
            placeholder="Your Email"
            autofocus=""
            :disabled="loading"
            v-model="form.email"
          />
        </div>
      </div>
      <button
        class="button is-block is-primary is-fullwidth is-rounded"
        @click.prevent="onSubmitClicked"
        :disabled="loading || invalid"
        :class="{ 'is-loading': loading }"
      >
        Submit
      </button>
      <p v-if="error" class="help is-danger">
        {{error}}
      </p>
      <p style="text-align:center;">
        <br/>
        <router-link :to="{ name: 'login' }">
          I remember my password!
        </router-link>
      </p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ForgotPasswordForm',
  data: function() {
    return {
      form: {
        email: ''
      },
      loading: false,
      error: null
    }
  },
  computed: {
    invalid: function() {
      return !this.form.email
    }
  },
  methods: {
    trySubmit: async function() {
      try {

        const response = await this.$fetch('POST', 'password_resets.create', this.form)

        if (response.error) {
          return response.error.message
        } else if(typeof response !== 'object') {
          return 'An unknown error has occurred. Please try again.'
        }

        return false

      } catch(e) {
        return e
      }
    },
    onSubmitClicked: async function() {
      this.error = null
      this.loading = true

      const error = await this.trySubmit()
      if (error) this.$set(this.$data, 'error', error)
      else this.$emit('submitted')

      this.loading = false
    }
  }
}
</script>

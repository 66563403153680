<template>
  <PageView class="view">

    <template #header>
      <h2 class="title is-4">Reset Password</h2>
    </template>

    <div class="login-view__inner">

      <div v-if="error" style="width:100%;text-align:center;">
        {{ error }}
      </div>

      <div v-else-if="success" style="width:100%;text-align:center;">
        <p>Your password has been successfully reset!</p>
        <p>Click <router-link :to="{ name: 'login' }">here</router-link> to sign in</p>
      </div>

      <ResetPasswordForm
        v-else-if="password_reset"
        class="login-form"
        :code="code"
        @password-reset="onPasswordReset"
      />

      <div v-else>
        LOADING
      </div>

    </div>
  </PageView>
</template>

<script>
import PageView from '@/components/PageView.vue'
import ResetPasswordForm from '@/components/ResetPasswordForm.vue'

export default {
  name: 'ResetPassword',
  components: {
    PageView,
    ResetPasswordForm
  },
  data: function() {
    return {
      password_reset: null,
      success: false,
      error: null
    }
  },
  computed: {
    code: function() {
      return this.$route.params.code
    }
  },
  created() {
    this.fetchPasswordReset()
  },
  methods: {
    async fetchPasswordReset() {

      const response = await this.$fetch('GET', `password_resets.get?code=${this.code}`)
      if (response && response.error) {
        if (response.error.message.startsWith('Could not find PasswordReset')) {
          this.$set(this.$data, 'error', 'The link you followed is no longer valid')
        } else {
          this.$set(this.$data, 'error', response.error.message)
        }
      } else {
        this.$set(this.$data, 'password_reset', response)
      }

    },
    onPasswordReset() {
      this.success = true
    }
  }
}
</script>

<style lang="sass" scoped>
.login-view__inner
  display: flex
  height: 100%

.login-form
  margin: auto
</style>

<template>
  <PageView class="view">

    <template #header>
      <BackButton class="inline-button" />
      <h2 class="title is-4" v-if="program">
        Manage: {{ program.name }} ({{ program.code}}) <span v-if="!Object.keys(reviews_map).length"> - Assign Reviewers</span>
      </h2>

      <button
        v-if="login.role === 'admin'"
        class="button is-primary is-rounded is-unassign"
        @click="unassignAllReviewers"
        :disabled="unassigning"
        style="margin-left: 25px;"
      >
        Unassign all reviewers
      </button>
    </template>

    <template v-if="program && candidates && reviewers && reviews">

      <div class="assign-view" v-if="!Object.keys(reviews_map).length">

        <h3 class="assign-options">Automatically Assign Reviewers</h3>

        <form class="form" @submit="onFormSubmit" autocomplete="off">
          <div class="field">
            <label class="label">Reviewers</label>
            <div
              v-for="reviewer in reviewers"
              :key="reviewer.id"
              class="control"
            >
              <label class="checkbox">
                {{ reviewer.name }}
                <input
                  type="checkbox"
                  :value="reviewer.id"
                  v-model="selected_reviewers"
                />
              </label>
            </div>
            <div style="margin-top:10px;">
              <span @click="select_all" class="select-toggle">
                Select all reviewers
              </span> |
              <span @click="select_none" class="select-toggle">
                Deselect all reviewers
              </span>
            </div>
          </div>
          <div class="field">
            <label class="label">Reviews per Candidate</label>
            <div class="control">
              <div class="select">
                <select v-model="num_reviews">
                  <option
                    v-for="(reviewer, index) in reviewers"
                    :key="reviewer.id"
                    :value="index + 1"
                  >{{ index + 1 }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Maximum Candidates Per Reviewer</label>
            <div class="control">
              <div class="select">
                <select v-model="num_assigns">
                  <option
                    v-for="(assign, index) in assigns"
                    :key="assign"
                    :value="index + 1"
                  >{{ index + 1 }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <button
                class="button is-link"
                :disabled="loading"
              >Submit</button>
            </div>
          </div>
        </form>
      </div>

      <table v-else class="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th
              key="name"
              @click="onHeaderClick({ value: 'name' })"
              class="table-header table-header-left"
            >
              <span>Candidate</span>
              <template>
                <template v-if="descending">
                  <span
                    class="sort-arrow-down"
                    :class="{ active: sort === 'name' }"
                  />
                </template>
                <template v-else>
                  <span
                    class="sort-arrow-up"
                    :class="{ active: sort === 'name' }"
                  />
                </template>
              </template>
            </th>
            <th
              key="school"
              @click="onHeaderClick({ value: 'school' })"
              class="table-header"
            >
              <span>School</span>
              <template>
                <template v-if="descending">
                  <span
                    class="sort-arrow-down"
                    :class="{ active: sort === 'school' }"
                  />
                </template>
                <template v-else>
                  <span
                    class="sort-arrow-up"
                    :class="{ active: sort === 'school' }"
                  />
                </template>
              </template>
            </th>
            <th
              v-for="reviewer in reviewers"
              :key="reviewer.id"
              @click="onHeaderClick({ value: reviewer.id, type: 'reviewer'})"
              class="table-header table-header-data"
            >
              <span>{{ reviewer.name }} ({{ reviewer.assigned_reviews }})</span>
              <template>
                <template v-if="descending">
                  <span
                    class="sort-arrow-down"
                    :class="{ active: sort === reviewer.id }"
                  />
                </template>
                <template v-else>
                  <span
                    class="sort-arrow-up"
                    :class="{ active: sort === reviewer.id }"
                  />
                </template>
              </template>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="candidate in sorted_candidates"
            :key="candidate.id"
          >
            <td class="table-row-left">
              {{ candidate.name }} ({{ candidate.carms_id }})
            </td>
            <td>{{ candidate.school }}</td>
            <td
              v-for="reviewer in reviewers"
              :key="reviewer.id"
            >
              <div class="control">
                <button
                  v-if="reviews_map[reviewer.id] && reviews_map[reviewer.id][candidate.id]"
                  :disabled="loading"
                  class="button is-danger is-small is-rounded"
                  @click="unassignReviewer(reviewer, candidate)"
                >
                  Unassign
                </button>
                <button
                  v-else
                  :disabled="loading"
                  class="button is-primary is-small is-rounded"
                  @click="assignReviewer(reviewer, candidate)"
                >
                  Assign
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </template>

    <template v-else>
      LOADING
    </template>

  </PageView>
</template>

<script>
import AdminMixin from '@/mixins/admin'
import BackButton from '@/components/BackButton.vue'
import PageView from '@/components/PageView.vue'

export default {
  name: 'ManageProgramView',
  components: {
    BackButton,
    PageView
  },
  data: function() {
    return {
      program: null,
      candidates: null,
      reviewers: null,
      reviews: null,
      expanded: false,
      selected_reviewers: [],
      num_reviews: 1,
      num_assigns: 2,
      assigns: [...Array(25).keys()],
      loading: false,
      sort: 'name',
      sort_type: null,
      descending: true,
      unassigning: false,
    }
  },
  mixins: [AdminMixin],
  computed: {
    program_id: function() {
      return parseInt(this.$route.params.id)
    },
    reviews_map: function() {

      if (!this.reviews) return {}

      const reviews_map = {}
      this.reviews.forEach((review) => {
        if (!reviews_map[review.login_id]) {
          reviews_map[review.login_id] = {}
        }
        if (!reviews_map[review.login_id][review.candidate_id]) {
          reviews_map[review.login_id][review.candidate_id] = true
        }
      })
      return reviews_map
    },
    sorted_candidates: function() {

      if (!this.candidates) return null

      const sorted_candidates = this.candidates.slice()

      sorted_candidates.sort((a, b) => {
        let value = (a[this.sort] < b[this.sort]) ? -1 : 1
        if (this.sort_type === 'reviewer') {
          const a_value = this.reviews_map[value][a.id] || false
          const b_value = this.reviews_map[value][b.id] || false
          if (this.descending) return a_value - b_value
          return b_value - a_value
        }
        return this.descending ? -value : value
      })

      return sorted_candidates

    },
    schools () {
      return this.candidates
        .map((candidate) => candidate.school)
        .reduce((acc, school) => {
          if (!acc.includes(school)) {
            acc.push(school)
          }
          return acc
        }, [])
    },
  },
  created() {
    this.initialize()
  },
  methods: {

    onHeaderClick({ value, type }) {
      this.sort = value
      this.descending = !this.descending
      this.sort_type = type
    },

    initialize() {
      if (!this.program) this.fetchProgram()
      if (!this.candidates) this.fetchCandidates()
      if (!this.reviewers) this.fetchReviewers()
      if (!this.reviews) this.fetchReviews()
    },

    select_all () {
      const selected = this.reviewers.map((reviewer) => reviewer.id)
      this.$set(this.$data, 'selected_reviewers', selected)
    },

    select_none () {
      this.$set(this.$data, 'selected_reviewers', [])
    },

    async fetchProgram() {
      const program = await this.$fetch('GET', `programs.get?id=${this.program_id}`)
      this.$set(this.$data, 'program', program)
    },

    async fetchCandidates() {
      const candidates = await this.$fetch('GET', `candidates.list?program_id=${this.program_id}`)
      this.$set(this.$data, 'candidates', candidates)
    },

    async fetchReviewers() {
      const reviewers = await this.$fetch('GET', `logins.active?program_id=${this.program_id}`)
      this.$set(this.$data, 'reviewers', reviewers)
    },

    async fetchReviews() {
      const reviews = await this.$fetch('GET', `reviews.list?program_id=${this.program_id}`)
      this.$set(this.$data, 'reviews', reviews)
    },

    async onFormSubmit(e) {

      e.preventDefault()

      if (this.selected_reviewers.length < this.num_reviews) {
        alert(`Must have at least ${this.num_reviews} reviewer(s) selected`)
        return
      }

      this.loading = true

      const response = await this.$fetch('POST', `reviews.assign?program_id=${this.program_id}`, {
        reviewer_ids: this.selected_reviewers,
        num_reviews: this.num_reviews,
        num_assigns: this.num_assigns,
      })

      this.loading = false

      if (response.error) {
        alert(response.error.message || 'An unknown error has occurred')
      }

      this.expanded = false
      this.$set(this.$data, 'reviews', null)
      this.initialize()

    },

    async assignReviewer(reviewer, candidate) {

      this.loading = true

      const review = await this.$fetch('POST', `reviews.create?reviewer_id=${reviewer.id}&candidate_id=${candidate.id}`)
      this.reviews.push(review)
      reviewer.assigned_reviews++

      this.loading = false

    },

    async unassignReviewer(reviewer, candidate) {

      if (!confirm(`Are you sure you want to unassign ${reviewer.name} from ${candidate.name}? Their review will be deleted.`)) {
        return
      }

      this.loading = true

      await this.$fetch('POST', `reviews.delete?reviewer_id=${reviewer.id}&candidate_id=${candidate.id}`)
      const index = this.reviews.findIndex((review) => {
        return review.login_id === reviewer.id && review.candidate_id === candidate.id
      })
      this.$delete(this.reviews, index)
      reviewer.assigned_reviews--

      this.loading = false

    },

    async unassignAllReviewers() {

      if (!confirm(`Are you sure you want to unassign all reviewers? All of their reviews will be deleted.`)) {
        return
      }

      this.loading = true

      const review_ids = this.reviews.map((review) => review.id)

      await this.$fetch('POST', 'reviews.delete_many', { review_ids })
      this.reviews = []

      this.loading = false
    }

  }
}
</script>

<style lang="sass" scoped>
.view
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  max-height: calc(100vh - 114px)

/deep/ .page-view__section
  padding: 0
  overflow-x: auto

/deep/ .page-view__header .title
  flex: 1

.table-header
  cursor: pointer
  position: sticky
  top: 0
  left: 0
  z-index: 8
  background: rgba(255,255,255,0.95)
  min-width: 320px

.table-header-left
  z-index: 9

.table-header-data
  min-width: 250px

.table-row-left
  position: sticky
  left: 0
  z-index: 1

.search-candidates-view
  position: relative
  max-width: 100%

.form
  max-width: 720px
  margin: auto

.assign-options
  max-width: 720px
  margin: auto
  padding: 1rem
  font-size: 24px

.button.is-unassign
  background: #f14668
  color: white
  font-size: 0.75rem

.form
  padding: 0 1rem 0 1rem

.table
  td
    vertical-align: middle
  tbody
    tr
      td
        background: transparentize(#fff, 0.05)
      &:nth-child(odd)
        td
          background: transparentize(#fafafa, 0.05)
</style>

<template>
  <flex class="view__not-found" height="100%">
    <flex-item width="100%" height="100%">
      <container>
        <h1>Page not found</h1>
        <p>The page you're looking for could not be found.</p>
      </container>
    </flex-item>
  </flex>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
.view__not-found
</style>

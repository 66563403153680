import NotFound from '@/views/NotFound.vue'
import LoginView from '@/views/Login.vue'
import ForgotPasswordView from '@/views/ForgotPassword.vue'
import ResetPasswordView from '@/views/ResetPassword.vue'
import UpdatePasswordView from '@/views/UpdatePassword.vue'
import ImportView from '@/views/Import.vue'
import ProgramsView from '@/views/Programs.vue'
import ProgramView from '@/views/Program.vue'
import ManageProgramView from '@/views/ManageProgram.vue'
import ReviewView from '@/views/Review.vue'
import AdminUsersView from '@/views/AdminUsers.vue'
import AdminUsersEditView from '@/views/AdminUsersEdit.vue'

export default [

  // Authorization

  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },

  {
    path: '/forgot_password',
    name: 'forgot-password',
    component: ForgotPasswordView,
  },

  {
    path: '/reset_password/:code',
    name: 'reset-password',
    component: ResetPasswordView,
  },

  {
    path: '/update_password',
    name: 'update-password',
    component: UpdatePasswordView,
  },

  // Programs

  {
    path: '/',
    name: 'programs',
    component: ProgramsView,
  },

  {
    path: '/programs/:id',
    name: 'program',
    component: ProgramView,
  },

  {
    path: '/programs/:id/manage',
    name: 'manage-program',
    component: ManageProgramView,
  },

  {
    path: '/programs/:program_id/candidates/:candidate_id/review',
    name: 'review',
    component: ReviewView,
  },

  {
    path: '/programs/:program_id/candidates/:candidate_id/review/:review_id',
    name: 'review-id',
    component: ReviewView,
  },

  // Import

  {
    path: '/import',
    name: 'import',
    component: ImportView,
  },

  // Admin

  {
    path: '/admin/users',
    name: 'admin-users',
    component: AdminUsersView,
  },

  {
    path: '/admin/users/create',
    name: 'admin-users-create',
    component: AdminUsersEditView,
  },

  {
    path: '/admin/users/:user_id',
    name: 'admin-users-edit',
    component: AdminUsersEditView,
  },

  // Other routes

  {
    path: '/*',
    name: 'not-found',
    component: NotFound,
    meta: {
      title: 'Page Not Found'
    }
  }

]

<template>
  <table class="candidates table is-fullwidth is-striped is-hoverable">
    <colgroup>
      <template v-for="(field, key) in form">
        <col
          :key="key"
          :width="field.width"
        />
      </template>
    </colgroup>
    <thead>
      <tr>
        <th
          v-for="(field, key) in form"
          :key="key"
          @click="field.sortable && onHeaderClick(key)"
          style="cursor:pointer"
        >
          <span>{{ field.label }}</span>
          <template>
            <template v-if="descending">
              <span
                class="sort-arrow-down"
                :class="{ active: sort === key }"
              />
            </template>
            <template v-else>
              <span
                class="sort-arrow-up"
                :class="{ active: sort === key }"
              />
            </template>
          </template>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="candidate in sorted_candidates"
        :key="candidate.id"
      >
        <td
          v-for="(field, key) in form"
          :key="key"
        >
          <template v-if="key === 'controls'">
            <div
              class="control"
            >
              <button
                v-if="candidate.reviews[0].status === 'incomplete'"
                class="button is-primary is-small is-rounded"
                @click="editReview(candidate.id)"
              >
                Start Review
              </button>
              <button
                v-else
                class="button is-small is-rounded"
                @click="editReview(candidate.id)"
              >
                Edit Review
              </button>
            </div>
          </template>
          <template v-else-if="key === 'complete'">
            <span v-if="candidate[key]" class="tag is-success">Completed</span>
            <span v-else class="tag is-danger">Incomplete</span>
          </template>
          <template v-else>
            {{ candidate[key] }}
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'ReviewerCandidates',
  props: {
    candidates: {
      type: Array
    },
    program_id: {
      type: Number
    }
  },
  data: function() {
    return {
      form: {
        carms_id: {
          label: 'CaRMS ID',
          width: '15%',
          sortable: true
        },
        name: {
          label: 'Name',
          width: '25%',
          sortable: true
        },
        school: {
          label: 'School',
          width: '25%',
          sortable: true
        },
        total_score: {
          label: 'Total Score',
          width: '10%',
          sortable: true
        },
        complete: {
          label: 'Complete',
          width: '15%',
          sortable: true
        },
        controls: {
          label: '',
          width: '10%'
        }
      },
      sort: 'complete',
      descending: true
    }
  },
  computed: {
    sorted_candidates: function() {

      if (!this.candidates) return null

      const sorted_candidates = this.candidates.slice()

      sorted_candidates.sort((a, b) => {
        const value = (a[this.sort] < b[this.sort]) ? -1 : 1
        return this.descending ? -value : value
      })

      return sorted_candidates

    }
  },
  methods: {

    onHeaderClick(key) {
      this.$set(this.$data, 'sort', key)
      this.$set(this.$data, 'descending', !this.descending)
    },

    editReview(candidate_id) {
      this.$router.push({
        name: 'review',
        params: {
          program_id: this.program_id,
          candidate_id: candidate_id
        }
      })
    }
  }
}
</script>

<template>
  <PageView class="view">
    <template #header>
      <h2 class="title is-4">Forgot Password</h2>
    </template>

    <div class="login-view__inner">

      <div v-if="success" style="width:100%;text-align:center;">
        <p>An email with instructions on how to reset your password has been sent!</p>
        <p>Please check your spam folder if it does not appear in the next few minutes.</p>
      </div>

      <ForgotPasswordForm
        v-else
        class="login-form"
        @submitted="onSubmitted"
      />

    </div>
  </PageView>
</template>

<script>
import PageView from '@/components/PageView.vue'
import ForgotPasswordForm from '@/components/ForgotPasswordForm.vue'

export default {
  name: 'ForgotPassword',
  components: {
    PageView,
    ForgotPasswordForm
  },
  data: function() {
    return {
      success: false
    }
  },
  methods: {
    onSubmitted: function() {
      this.success = true
    }
  }
}
</script>

<style lang="sass" scoped>
.login-view__inner
  display: flex
  height: 100%

.login-form
  margin: auto
</style>

<template>
  <div>
    <template v-for="review in sorted_reviews">
      <ReviewModal 
        ref="review" 
        :key="review.id" 
        :form="form" 
        :review="review" 
        :program_id="program_id"
        @expanded="closeAll" 
      />
    </template>
  </div>
</template>

<script>
import ReviewModal from '@/components/ReviewModal.vue'

export default {
  name: 'ReviewsModal',
  components: {
    ReviewModal
  },
  props: {
    program_id: {
      type: Number
    },
    reviews: {
      type: Array
    }
  },
  data: function() {
    return {
      form: {
        reviewer: {
          label: 'Reviewer'
        },
        total_score: {
          label: 'Total Score'
        },
        avg_diff: {
          label: 'Difference from Average'
        },
        nonmedical_education: {
          label: 'Professional or Post-Bacculaureate Degree',
          description: 'The applicant has obtained a professional or post-bacculaureate degree in AT LEAST one of the following fields: any Health Care Profession, Masters, PhD, Engineering, Law, CPA, Veterinary.'
        },
        clinical_electives: {
          label: 'Clinical Electives',
          description: 'The applicant has EITHER completed 50% or more of their electives in internal medicine or a subspecialty, including critical care, OR has an ecclectic mix of electives with no area greater than 50%.'
        },
        work_experience: {
          label: 'Work Experience',
          description: 'The applicant has completed AT LEAST 6 months of substantial and sustained work in one work environment.'
        },
        work_life_balance: {
          label: 'Work / Life Balance',
          description: 'Any evidence of extra-curricular interests and balance. This DOES NOT include volunteering.'
        },
        current_work_life_balance: {
          label: 'Current Work / Life Balance',
          description: 'Any evidence of CURRENT and SUSTAINED (> 6 months) extra-curricular interests and balance. This DOES NOT include volunteering.'
        },
        research_activity: {
          label: 'Research Activity',
          description: 'The applicant has some research activity, including non-peer reviewed publications and abstract presentations. This DOES NOT include presentations given as part of a clinical rotation.'
        },
        peer_reviews: {
          label: 'Peer Reviews',
          description: 'The applicant has AT LEAST one peer reviewed publication.'
        },
        authorships: {
          label: 'Authorships',
          description: 'The applicant has AT LEAST one peer reviewed publication where they are listed as the first author.'
        },
        volunteer_experience: {
          label: 'Volunteering',
          description: 'The applicant has completed AT LEAST 6 months of volunteer participation during their medical schooling.'
        },
        medical_school_leadership: {
          label: 'Medical School Leadership or Involvement',
          description: 'Significant leadership role during Medical School (E.g. founder or Executive of interest group or Student Association, any provincial or national role) (2); involvement - any extracurricular medical school activities demonstrating contribution to the med school community (1).'
        },
        leadership: {
          label: 'Leadership or Accomplishment',
          description: 'Significant local provincial or national leadership position outside of medical school OR significant accomplishment in applicant\'s field of interest at a local, provincial or national level.'
        },
        personal_letter: {
          label: 'Personal Letter',
          description: 'The applicant has provided a personal letter along with their application.'
        },
        reference_letter: {
          label: 'Letter of Reference',
          description: 'The applicant has provided AT LEAST one letter of reference along with their application.'
        },
        second_reference_letter: {
          label: 'Second Letter of Reference',
          description: 'The applicant has provided AT LEAST two letters of reference along with their application.'
        },
        overall_impression: {
          label: 'Overall Impression'
        }
      }
    }
  },

  computed: {
    sorted_reviews: function() {

      if (!this.reviews) return null

      const sorted_reviews = this.reviews.slice()

      sorted_reviews.sort((a, b) => {
        a = a.status === 'completed' ? 1 : 0
        b = b.status === 'completed' ? 1 : 0
        return a < b ? 1 : -1
      })

      return sorted_reviews

    }
  },

  methods: {
    closeAll () {
      this.$refs.review.forEach((ref) => ref.collapse())
    }
  }
}
</script>
